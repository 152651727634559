<template>
  <div class='mb-1'>
    <div class='d-none'>
      <input type='hidden' :name='inputNameFor("i")' :value='query.attributeIdentifier'>
      <input type='hidden' v-if='hasComperators' :name='inputNameFor("c")' :value='query.comperator'>
      <input type='hidden' v-if='hasValue' :name='inputNameFor("v")' :value='query.value'>
    </div>
    <div class='row no-gutters'>
      <div class='col-3 mr-1'>
        <SelectInput class='custom-select-sm' v-model='query.attributeIdentifier' :options='attributeSelectOptions'></SelectInput>
      </div>
      <div class='col-3 mr-1' v-if='hasComperators || !attribute'>
        <SelectInput class='custom-select-sm' v-model='query.comperator' :options='comperatorSelectOptions' :disabled='!attribute'></SelectInput>
      </div>
      <div class='col mr-1' v-if='hasValue || !attribute'>
        <input v-if='provideSearchField' v-model.trim='query.value' :placeholder='placeholder' :disabled='!attribute' class='form-control form-control-sm'/>
        <SelectInput v-else class='custom-select-sm' v-model='query.value' :options='searchOptions' />
      </div>
      <div class='col' v-if='attribute && !hasValue'></div>
      <div class='col-auto'>
        <button type='button' class='btn btn-danger btn-sm' @click='$emit("remove")'>
          <fa-icon icon='trash' />
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import SelectInput from "./select_input"

export default {
  props: [ "query", "groupedAttributes", "types", "idx" ],
  components: { SelectInput },
  computed: {
    active() { return this.query.isActive() },
    attributes() {
      const attributes = []

      this.groupedAttributes.forEach((attribute_or_group) => {
        if (attribute_or_group.type == "group") {
          attribute_or_group.attributes.forEach((attribute) => attributes.push(attribute))
        } else {
          attributes.push(attribute_or_group)
        }
      })

      return attributes
    },
    attribute() { return this.attributes.find((a) => a.identifier == this.query.attributeIdentifier) },
    typeName() { return (this.attribute ? this.attribute.type : null) },
    type() { return this.findType(this.typeName) },
    comperatorName() { return this.query.comperator },
    comperator() { return (this.comperatorName ? this.comperators.find((c) => c.identifier == this.comperatorName) : null ) },

    hasComperators() { return this.type && !!this.type.comperators },
    hasValue() { return this.type && !this.type.predefined },

    placeholder() { return this.attribute && this.attribute.placeholder ? this.attribute.placeholder : this.genericInputPlaceholder },
    comperators() { return this.type && this.hasComperators ? this.type.comperators : [] },
    searchOptions() { return this.type && this.type.options ? this.type.options : [] },

    provideSearchOptions() { return this.searchOptions.length > 0 },
    provideSearchField() { return !this.provideSearchOptions },

    genericInputPlaceholder() {
      const parts = []

      if (this.attribute) {
        parts.push(this.attribute.title)
      }

      if (this.comperator) {
        parts.push(this.comperator.title)
      }

      if (parts.length > 0) {
        return `${parts.join(" ")} ...`
      } else {
        return ""
      }
    },


    attributeSelectOptions() {
      const options = []

      const buildAttributeOption = (attribute) => {
        return {
          value: attribute.identifier,
          text: attribute.title,
        }
      }

      options.push({ value: null, text: "" })

      this.groupedAttributes.forEach((attribute) => {
        if (attribute.type != "group") {
          options.push(buildAttributeOption(attribute));
        } else {
          options.push({
            type: "group",
            text: attribute.title,
            options: attribute.attributes.map((attribute) => buildAttributeOption(attribute))
          });
        }
      })

      return options
    },
    comperatorSelectOptions() {
      return this.comperators.map((comperator) => {
        return {
          value: comperator.identifier,
          text: comperator.title
        }
      })
    }
  },
  watch: {
    attribute(to, from) {
      if (from === to || (from && to && from.type == to.type)) { return }

      const from_type = from && this.findType(from.type)
      if (from_type && !!from_type.options) {
        this.query.value = ""
      }

      const to_type = to && this.findType(to.type)
      if (to_type && !!to_type.options) {
        this.query.value = to_type.options[0].value
      }

      const firstComperator = this.comperators[0]
      if (firstComperator) {
        this.query.comperator = firstComperator.identifier
      }
    }
  },
  methods: {
    inputNameFor(input) { return `as[${this.idx}][${input}]` },
    findType(named) { return named ? this.types.find((t) => t.type == named) : null },
  }
}

</script>