import { Controller } from 'stimulus'
import ClipboardJS from 'clipboard'

export default class ClipboardController extends Controller {

  static targets = ["default", "copied", "error"]

  get isSupported() { return ClipboardJS.isSupported() }
  get text() { return this.data.get("text") }
  get copiedDelay() { return parseFloat(this.data.get("delay")) * 1000 }


  // =============
  // = Lifecycle =
  // =============
  connect() {
    if (this.isSupported){
      this.showState("default")
      this.setupClipboard()
    } else {
      this.hideCompletely()
    }
  }

  disconnect() {
    this.clipboard.destroy()
  }

  // =========
  // = Setup =
  // =========
  setupClipboard() {
    const clipboard = new ClipboardJS(this.element, {
      text: () => this.text,
    })

    clipboard.on("success", () => this.copied());
    clipboard.on("error", () => this.errored());

    this.clipboard = clipboard
  }

  hideCompletely() {
    this.element.classList.add("d-none")
  }

  // ==================
  // = State Handling =
  // ==================

  copied() {
    this.clearResetTimeout()
    this.showState("copied")
    this.resetDelay = setTimeout(() => {
      this.resetStateAfterDelay()
    }, this.copiedDelay)
  }

  errored() {
    this.clearResetTimeout()
    this.showState("error")
    this.resetDelay = setTimeout(() => {
      this.resetStateAfterDelay()
    }, this.copiedDelay)
  }

  clearResetTimeout() {
    if (this.resetDelay) {
      clearTimeout(this.resetDelay)
      this.resetDelay = null
    }
  }

  resetStateAfterDelay() {
    this.resetDelay = null
    this.showState("default")
  }

  showState(state) {
    switch (state) {
    case "default":
      this.enableButton()
      this.enableStateTarget(this.defaultTarget)
      break;
    case "copied":
      this.disableButton()
      this.enableStateTarget(this.copiedTarget)
      break;

    case "error":
      this.disableButton()
      this.enableStateTarget(this.errorTarget)
    }
  }

  disableButton() {
    this.element.classList.add("disabled")
  }

  enableButton() {
    this.element.classList.remove("disabled")
  }

  enableStateTarget(target) {
    const targets = [this.defaultTarget, this.copiedTarget, this.errorTarget]

    targets.forEach((aTarget) => {
      if (target == aTarget) {
        aTarget.classList.remove("d-none")
      } else {
        aTarget.classList.add("d-none")
      }
    })
  }

}