<template>
  <select v-model='currentValue' class='form-control custom-control custom-select'>
    <template v-for='option in options'>
      <optgroup v-if='option.type == "group"' :label='option.text'>
        <option v-for='child in option.options' :value='child.value'>{{ child.text }}</option>
      </optgroup>
      <option v-else :value='option.value'>{{ option.text }}</option>
    </template>
  </select>
</template>

<script>

export default {
  props: [ "value", "options" ],
  computed: {
    currentValue: {
      get() { return this.value },
      set(value) { this.$emit("input", value) }
    }
  }
}

</script>