

import { library } from '@fortawesome/fontawesome-svg-core'


import { faList as fasList, faTable as fasTable, faSearch as fasSearch, faPlus as fasPlus, faPencilAlt as fasPencilAlt, faTrash as fasTrash, faCheck as fasCheck, faExclamationTriangle as fasExclamationTriangle, faClipboard as fasClipboard, faStar as fasStar, faCircle as fasCircle, faFile as fasFile, faUsers as fasUsers, faBook as fasBook, faUser as fasUser, faSpinner as fasSpinner, faSortAmountDownAlt as fasSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons"


import { faStar as farStar, faCircle as farCircle, faFilePdf as farFilePdf } from "@fortawesome/free-regular-svg-icons"




export function setup() {
  
  library.add(fasList);
  
  library.add(fasTable);
  
  library.add(fasSearch);
  
  library.add(fasPlus);
  
  library.add(fasPencilAlt);
  
  library.add(fasTrash);
  
  library.add(fasCheck);
  
  library.add(fasExclamationTriangle);
  
  library.add(fasClipboard);
  
  library.add(fasStar);
  
  library.add(fasCircle);
  
  library.add(fasFile);
  
  library.add(fasUsers);
  
  library.add(fasBook);
  
  library.add(fasUser);
  
  library.add(fasSpinner);
  
  library.add(fasSortAmountDownAlt);
  
  library.add(farStar);
  
  library.add(farCircle);
  
  library.add(farFilePdf);
  
}
