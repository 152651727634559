<template>
  <div>
    <Query
      v-for='query, idx in queries'
      :key='query.id'
      :query='query'
      :types='types'
      :grouped-attributes='groupedAttributes'
      :idx='idx'
      @remove='removeQuery(query)'
      />
  </div>
</template>

<script>

import QueryModel from "./advanced_search/models/query"
import Query from "./advanced_search/components/query"

export default {
  props: [
    "currentQueries",
    "groupedAttributes",
    "types",
    "i18n"
  ],
  data() {
    const dataObj =  {
      queries: this.parseQueries(this.currentQueries),
    }

    return dataObj
  },
  components: { Query },
  mounted() {
    this.ensureEmptyQuery()
  },
  computed: {
    lastQuery() { return this.queries[this.queries.length - 1] },
    lastQueryActiveness() { return this.lastQuery && this.lastQuery.isActive() },
  },
  watch: {
    lastQueryActiveness: {
      immediate: true,
      handler() {
        if (this.lastQueryActiveness) {
          this.ensureEmptyQuery()
        }
      }
    }
  },
  methods: {
    parseQueries(rawQueries) {
      return rawQueries.map((rawQuery) => {
        const query = QueryModel.build()

        query.attributeIdentifier = rawQuery.identifier
        query.comperator = rawQuery.comperator
        query.value = rawQuery.value

        return query
      })
    },

    ensureEmptyQuery() {
      if (!this.lastQuery || this.lastQuery.isActive()) {
        this.buildQuery()
      }
    },

    buildQuery() {
      const query = QueryModel.build()

      this.queries.push(query)
    },

    removeQuery(query) {
      const idx = this.queries.indexOf(query)

      if (idx != -1) {
        this.queries.splice(idx, 1)
        this.ensureEmptyQuery()
      }
    }
  }
}

</script>