import $ from 'jquery'

const Selector = {
  DIALOG: ".modal-dialog",
  TITLE:  ".modal-title",
  BODY:   ".modal-body",
  FOOTER: ".modal-footer",
}

export default class ModalView {
  static build() {
    const template = `<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class='modal-title'></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>`

    const $container = $(template)

    return new ModalView($container)
  }

  set size(size) { this.updateSize(size) }
  set verticallyCentered(value) { this.dialogElement.toggleClass("modal-dialog-centered", value) }

  set title(value) { this.titleContent = value }
  set body(value)   { this.bodyContent = value   }
  set footer(value) { this.footerContent = value }

  constructor(element) {
    this.element = element

    this.titleContent  = ""
    this.bodyContent   = ""
    this.footerContent = ""

    this.fetchElements()
  }

  // =========
  // = Setup =
  // =========

  fetchElements() {
    this.dialogElement  = this.element.find(Selector.DIALOG)
    this.titleElement   = this.element.find(Selector.TITLE)
    this.bodyElement    = this.element.find(Selector.BODY)
    this.footerElement  = this.element.find(Selector.FOOTER)
  }

  // =============
  // = Lifecycle =
  // =============

  appendTo($element) {
    this.element.appendTo($element)
  }

  // ===========
  // = Helpers =
  // ===========

  updateSize(newSize) {
    const sizes = [
      { size: "sm", dialogClass: "modal-sm" },
      { size: "md", dialogClass: null },
      { size: "lg", dialogClass: "modal-lg" },
      { size: "xl", dialogClass: "modal-xl" },
    ]

    sizes.forEach((sizeDefinition) => {
      const size = sizeDefinition.size
      const cssClass = sizeDefinition.containerClass

      if (cssClass) {
        this.dialogElement.toggleClass(cssClass, size == newSize)
      }
    })
  }

  // ===========
  // = Actions =
  // ===========

  show() {
    this.element.modal("show")
  }

  hide() {
    this.element.modal("hide")
  }

  toggle() {
    this.element.modal("toggle")
  }

  refresh() {
    this.titleElement.html(this.titleContent)
    this.bodyElement.html(this.bodyContent)

    if (this.footerContent){
      this.footerElement.show()
      this.footerElement.html(this.footerContent)
    } else {
      this.footerElement.hide()
    }

    this.element.modal("handleUpdate")

    this.element.on("ajax:beforeStart", (e) => alert("b4 ajax start "))
  }

  destroy() {
    this.element.modal("dispose")
    this.element.remove()
  }

  trigger(event, data) {
    this.element.trigger(event, data)
  }

  // ==================
  // = Event Handling =
  // ==================

  on(eventName, targetSelector, callback) {
    this.element.on(eventName, targetSelector, callback)
  }

  off(eventName) {
    this.element.on(eventName)
  }
}