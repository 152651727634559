let id = 0

export default class Query {
  static build() {
    return new Query(id++)
  }

  constructor(id) {
    this.id = id
    this.attributeIdentifier = null
    this.value = null
    this.comperator = ""
  }

  isActive() { return !!this.value }
}