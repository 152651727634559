import { Controller } from 'stimulus'

import $ from "jquery"

import "vendor/selectize"

import RemoteModal from "src/components/remote_modal"

import I18n from "src/i18n"

export default class SelectizeController extends Controller {
  static targets = []

  get $form() { return $(this.element).closest("form") }

  get multi() { return this.data.get("multi") == "true" }
  get single() { return !this.multi }
  get maxItems() { return parseInt(this.data.get("max-items")) }
  get searchUrl() { return this.data.get("search-url") }
  get searchParam() { return this.data.get("search-param") }
  get newUrl() { return this.data.get("new-url") }
  get newValueParam() { return this.data.get("new-param") }

  initialize() {
    this.eventNamespace = "selectizeController"
  }

  connect() {
    this.unsetValue()
    this.setupSelectize()
    this.addCurrentValues()
    this.removeFormControlClass()
  }

  disconnect() {
    if (this.selectize) {
      this.resetValue()

      this.selectize.destroy()
      delete this.selectize
    }
  }


  // ==================
  // = Value Handling =
  // ==================

  unsetValue() {
    this.previousValue = this.element.value
    this.element.value = ""
  }

  resetValue() {
    this.element.value = this.previousValue
  }

  // =============
  // = Selectize =
  // =============

  setupSelectize() {
    const options = this.selectizeOptions()
    const $select = $(this.element).selectize(options)

    this.selectize = $select.data("selectize")
  }

  addCurrentValues() {
    const currentValue = JSON.parse(this.data.get("value"))

    if (currentValue) {
      if (this.single) {
        this.selectize.addOption(currentValue)
        this.selectize.addItem(currentValue.value, true)
      } else {
        currentValue.forEach((value) => {
          this.selectize.addOption(value)
          this.selectize.addItem(value.value, true)
        })
      }
    }
  }

  removeFormControlClass() {
    const { parentElement } = this.element

    parentElement.querySelector(".selectize-control").classList.remove("form-control")
    parentElement.querySelector(".selectize-dropdown").classList.remove("form-control")
  }

  selectizeOptions() {
    const options = {
      mode: this.multi ? "multi" : "single",
      maxItems: this.multi ? this.maxItems : 1,
      hideSelected: true,
      persist: true,
      render: {
        option_create: (data, escape) => {
          const value = `<strong>${escape(data.input)}</strong>`
          const description = I18n.t("selectize.add_button").replace("%{value}", value)

          return `<div class="create">${description}</div>`
        }
      }
    }

    if (this.searchUrl) {
      options["load"] = this.load.bind(this)
    }

    if (this.newUrl) {
      options["create"] = this.createItem.bind(this)
    }

    if (this.multi) {
      options["plugins"] = [ 'remove_button' ]
    }

    return options
  }

  ensureSelectizeFocus() {
    this.selectize.blur()

    requestAnimationFrame(() => this.selectize.focus(), 1)
  }

  load(text, callback) {
    if (text.length == 0) {
      return callback()
    }
    const url = this.searchUrl
    const queryParam = encodeURIComponent(this.searchParam)
    const queryString = encodeURIComponent(text)

    fetch(`${url}?${queryParam}=${queryString}`)
      .then((res) => res.json())
      .then((json) => callback(json))
      .catch((e) => {
        console.error(e)
        callback([])
      })
  }

  createItem(text, callback) {
    const modal = new RemoteModal(`${this.newUrl}?${encodeURIComponent(this.newValueParam)}=${encodeURIComponent(text)}`)

    let finished = false
    const finish = (data) => {
      if (finished) { return }

      finished = true
      callback(data)
      this.ensureSelectizeFocus()
    }

    const applyAutofocus = () => modal.element.find("input[autofocus]").focus()

    modal.load().show()
    modal.on("remote-modal:success", applyAutofocus)
    modal.on("shown.bs.modal", applyAutofocus)
    modal.on("ajax:error", (e) => {
      const modalContent = this.extractRemoteJSON(e)

      modal.update(modalContent)
    })
    modal.on("ajax:success", (e) => {
      const data = this.extractRemoteJSON(e)

      finish(data)

      modal.hide()
    })
    modal.on("hidden.bs.modal", (e) => {
      finish()

      modal.destroy()
    })
  }

  // ================
  // = AJAX Helpers =
  // ================

  extractRemoteJSON(e) {
    return e.originalEvent.detail[0]
  }
}