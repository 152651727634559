import $ from "jquery"

import ModalView from "./modal/view"

export default class Modal {
  get element() { return this.view.element }

  set title(value)  { this.view.title = value; this.refreshUnlessPrevented() }
  set body(value)   { this.view.body = value; this.refreshUnlessPrevented() }
  set footer(value) { this.view.footer = value; this.refreshUnlessPrevented() }

  constructor(options) {
    this.parseOptions(options)

    this.setupElement()
  }

  update(data) {
    const { title, body, footer } = data

    this.preventRefresh = true
    this.title  = title
    this.body   = body
    this.footer = footer
    this.preventRefresh = false

    this.refresh()
  }

  // ===========
  // = Actions =
  // ===========

  show() {
    this.view.show()
    return this
  }

  hide() {
    this.view.hide()
    return this
  }

  toggle() {
    this.view.toggle()
    return this
  }

  destroy() {
    this.view.destroy()
    return this
  }

  // ==================
  // = Event Handling =
  // ==================

  on(eventName, targetSelector, callback) {
    this.view.on(eventName, targetSelector, callback)
  }

  off(eventName) {
    this.view.off(eventName)
  }

  // ====================
  // = Element Handling =
  // ====================

  setupElement() {
    this.view = ModalView.build()

    this.view.appendTo(this.parentElement)
  }

  buildElement() {
    return $container
  }

  // ===================
  // = Option Handling =
  // ===================

  parseOptions(options) {
    if (!options) {
      options = {}
    }

    this.size          = this.fetchOption(options, "size", "md")
    this.parentElement = this.fetchOption(options, "parent", $(document.body))
  }

  fetchOption(options, optionName, defaultValue) {
    if (options.hasOwnProperty(optionName)) {
      return options[optionName]
    } else {
      return defaultValue;
    }
  }

  // ==============
  // = Refreshing =
  // ==============
  refreshUnlessPrevented() {
    if (!this.preventRefresh) {
      this.refresh()
    }
  }

  refresh() {
    this.view.refresh()
  }
}