import "vendor/vue"

import { Controller } from 'stimulus'

import Vue from 'vue/dist/vue.esm'
import AdvancedSearch from "../components/advanced_search"

export default class AdvancedSearchController extends Controller {
  get queries() { return JSON.parse(this.data.get("queries")) }
  get groupedAttributes() { return JSON.parse(this.data.get("grouped-attributes")) }
  get types() { return JSON.parse(this.data.get("types")) }

  connect() {
    this.createContainer()
    this.setupVue()
  }

  disconnect() {
    this.destroyVue()
    this.removeContainer()
  }

  // ========================
  // = Container Management =
  // ========================

  createContainer() {
    const el = document.createElement("div")

    this.element.appendChild(el)
    this.container = el
  }

  removeContainer() {
    if (!this.container) { return }

    const { parentElement } = this.container
    if (parentElement) {
      parentElement.removeChild(this.container)
    }

    this.container = null
  }

  // =======
  // = Vue =
  // =======

  setupVue() {
    const props = {
      currentQueries: this.queries,
      groupedAttributes: this.groupedAttributes,
      types: this.types,
    }

    const options = {
      el: this.container,
      render: (h) => h(AdvancedSearch, {attrs: {...props}}),
    }

    this.vue = new Vue(options)
  }

  destroyVue() {
    if (!this.vue) { return }

    this.vue.$destroy()
    this.vue = null
  }
}