import $ from "jquery"
import Modal from "./modal"

const eventNS = "remote-modal"

export default class RemoteModal extends Modal {
  constructor(url, options) {
    super(options)

    this.url = url
  }

  load() {
    this.handleLoading()

    fetch(this.url)
      .then((res) => res.json())
      .then((json) => this.handleRemoteContent(json))
      .catch((e) => this.handleError(e))

    return this
  }
  // =================
  // = Loading Views =
  // =================

  handleLoading() {
    this.update({
      title: "Loading",
      body: "Loading Remote Content from " + this.url
    })

    this.triggerEvent("loading")
  }

  handleRemoteContent(content) {
    this.update(content)

    this.triggerEvent("success")
  }

  handleError(e) {
    const description = "An unknown error occurred."
    const retry = "Retry"
    const $errorMessage = $(`<div>
        <p>${description}</p>
        <button data-behavior="retry" class='btn btn-primary'>${retry}</button>
      </div>`)

    $errorMessage.find("[data-behavior=retry]").on("click", (e) => {
      e.preventDefault()

      this.load()
    })

    this.update({
      title: "Error",
      body: $errorMessage
    })

    this.triggerEvent("error")
  }

  // ==================
  // = Event Handling =
  // ==================

  triggerEvent(named) {
    this.view.trigger(`${eventNS}:${named}`)
  }
}