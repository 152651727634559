import { Controller } from 'stimulus'

export default class PendingPreviewController extends Controller {
  get url() { return this.data.get("url") }
  get updateDelay() { return parseInt(this.data.get("delay") || "1000") }

  connect() {
    this.installTimer()
  }

  disconnect() {
    this.uninstallTimer()
  }

  installTimer() {
    this.reschedule()
  }

  uninstallTimer() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = undefined
    }
  }

  reschedule() {
    this.timeout = setTimeout(this.tick.bind(this), this.updateDelay)
  }

  tick() {
    fetch(this.url)
      .then((response) => response.json())
      .then((json) => {
        if (json.status == "done") {
          this.displayPreview(json)
        } else {
          this.reschedule()
        }
      })
      .catch(() => this.reschedule())
  }

  displayPreview(json) {
    const $preview = $(json.html).addClass(this.cssClass)
    $(this.element).replaceWith($preview)
  }

}